import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import SEO from '../components/seo';
import { HeroState } from '../components/hero/hero-context';
import ContentColumn from '../components/content-column/content-column';
import PageWrapper from '../components/page-wrapper/page-wrapper';
import PreorderForm from '../components/preorder-form/preorder-form';
import PreorderBox from '../components/preorder-box/preorder-box';

const PreorderPage = () => {
  const { t } = useTranslation('preorder');

  return (
    <PageWrapper>
      <SEO title={t('title')} />
      <HeroState
        title={t('header.title')}
        subTitle={t('header.subTitle')}
        imageName="machine--side-black"
      />
      <Container>
        <Row>
          <ContentColumn
            md={6}
            colClassName="p-sm-0 pt-lg-4"
            className="justify-content-start"
          >
            <Trans i18nKey="content" ns="preorder">
              <p>Entworfen und handgefertigt von unseren Experten und Ingenieuren im schweizerischen Thun: Die MANUMENT Leva-Maschine exklusiv hier zu bestellen.</p>
              <p>Füllen Sie bitte das Formular aus, das MANUMENT Team wird in Kürze mit Ihnen Kontakt aufnehmen, um Ihre Anfrage zu beantworten und Ihre Bestellung zu koordinieren.</p>
              <p>Preis auf Anfrage</p>
            </Trans>
          </ContentColumn>
          <Col md={6} style={{ padding: 0 }}>
            <PreorderBox
              title={t('form.title')}
              className="h-100 align-items-center d-flex"
            >
              <PreorderForm />
            </PreorderBox>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

export default PreorderPage;
