import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import FormText from 'react-bootstrap/FormText';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';

import useForm from '../../hooks/usePreOrderForm';
import Image from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
  {
    image01: file(
      relativePath: { eq: "pre-order/Manument_Leva_Maschine_schwarz_2.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image02: file(
      relativePath: { eq: "pre-order/Manument_Leva_Maschine_weiss_2.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

const Form = ({ namespace }) => {
  const data = useStaticQuery(query);
  const { t } = useTranslation(namespace);
  const { state, setFieldValue, handleSubmit, toggleColor } = useForm(
    namespace,
    namespace
  );

  if (state.submitted) {
    return <p className="text-muted">{t('form.successMessage')}</p>;
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="container">
        <div className="row">
          {/*first name*/}
          <FormGroup className="col-12 col-lg-6 mb-4">
            <FormControl
              placeholder={t('form.firstName.placeholderText')}
              name="firstName"
              type="text"
              onChange={setFieldValue}
              style={{ textAlign: 'left' }}
            />
            {state.errors.firstName && (
              <FormText className="text-danger">
                {state.errors.firstName}
              </FormText>
            )}
          </FormGroup>
          {/*last name*/}
          <FormGroup className="col-12 col-lg-6 mb-4">
            <FormControl
              placeholder={t('form.lastName.placeholderText')}
              name="lastName"
              type="text"
              onChange={setFieldValue}
              style={{ textAlign: 'left' }}
            />
            {state.errors.lastName && (
              <FormText className="text-danger">
                {state.errors.lastName}
              </FormText>
            )}
          </FormGroup>
        </div>
        <div className="row">
          {/*mail*/}
          <FormGroup className="col-12 col-lg-6 mb-4">
            <FormControl
              placeholder={t('form.email.placeholderText')}
              name="email"
              type="email"
              onChange={setFieldValue}
              style={{ textAlign: 'left' }}
            />
            {state.errors.email && (
              <FormText className="text-danger">{state.errors.email}</FormText>
            )}
          </FormGroup>
          {/*phone*/}
          <FormGroup className="col-12 col-lg-6 mb-4">
            <FormControl
              placeholder={t('form.phone.placeholderText')}
              name="phone"
              type="tel"
              onChange={setFieldValue}
              style={{ textAlign: 'left' }}
            />
            {state.errors.phone && (
              <FormText className="text-danger">{state.errors.phone}</FormText>
            )}
          </FormGroup>
        </div>
        {/*Country*/}
        <div className="row">
          <FormGroup className="col-12 mb-4">
            <FormControl
              rows="4"
              placeholder={t('form.country.placeholderText')}
              name="country"
              type="text"
              onChange={setFieldValue}
              style={{ padding: '1em', resize: 'none', textAlign: 'left' }}
            />
            {state.errors.country && (
              <FormText className="text-danger">
                {state.errors.country}
              </FormText>
            )}
          </FormGroup>
        </div>
        {/*message*/}
        <div className="row">
          <FormGroup className="col-12 mb-4">
            <FormControl
              as="textarea"
              rows="4"
              placeholder={t('form.message.placeholderText')}
              name="message"
              type="text"
              onChange={setFieldValue}
              style={{ padding: '1em', resize: 'none', textAlign: 'left' }}
            />
            <small
              className="pt-1"
              style={{
                color: '#495057',
                position: 'absolute',
                right: '1em',
              }}
            >
              {state.values.message.length} / {state.charLimit}
            </small>
            {state.errors.message && (
              <FormText className="text-danger">
                {state.errors.message}
              </FormText>
            )}
          </FormGroup>
        </div>
        {/*color select*/}
        <h2 className="mb-4">{t('form.colorLabel')}</h2>
        <div className="row text-left">
          <FormGroup className="col-6 mb-4">
            <FormControl
              name="black"
              type="radio"
              id="select-black"
              checked={state.values.black === 'on'}
              onChange={toggleColor}
              style={{ display: 'none' }}
            />
            <label
              htmlFor="select-black"
              style={{
                display: 'block',
                cursor: 'pointer',
                border:
                  state.values.black === 'on'
                    ? '1px solid #95534e'
                    : '1px solid transparent',
              }}
            >
              <Image {...data.image01.childImageSharp} />
            </label>
            <p>{t('form.colorOption1')}</p>
          </FormGroup>
          <FormGroup className="col-6 mb-4">
            <FormControl
              name="white"
              type="radio"
              id="select-white"
              checked={state.values.white === 'on'}
              onChange={toggleColor}
              style={{ display: 'none' }}
            />
            <label
              htmlFor="select-white"
              style={{
                display: 'block',
                cursor: 'pointer',
                border:
                  state.values.white === 'on'
                    ? '1px solid #95534e'
                    : '1px solid transparent',
              }}
            >
              <Image {...data.image02.childImageSharp} />
            </label>
            <p>{t('form.colorOption2')}</p>
          </FormGroup>
        </div>
        {/*submit*/}
        <FormGroup className="mb-0">
          <Button type="submit" variant="outline-red" disabled={state.loading}>
            {t('form.buttonText')}
          </Button>
          {state.loading && <FormText>{t('form.loadingMessage')}</FormText>}
          {state.errors.form && (
            <FormText className="text-danger">{state.errors.form}</FormText>
          )}
        </FormGroup>
      </div>
    </form>
  );
};

export default Form;
