import React from 'react';
import classnames from 'classnames';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from './preorder-box.module.scss';

const PreorderBox = ({ title, subTitle, children, className, ...props }) => (
  <div className={classnames(className, styles.root)} {...props}>
    <Container>
      <Row>
        <Col lg={{ offset: 0, span: 12 }}>
          {title && <h2 className={styles.title}>{title}</h2>}
          {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
          {children}
        </Col>
      </Row>
    </Container>
  </div>
);

export default PreorderBox;
